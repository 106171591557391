import { Routes } from "@angular/router";
import { AdminGuard } from "../guard/admin.guard";

export const content: Routes = [
  {
    path: "dashboard",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "widgets",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/widgets/widgets.module").then((m) => m.WidgetsModule),
  },
  {
    path: "ui-kits",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/ui-kits/ui-kits.module").then((m) => m.UiKitsModule),
  },
  {
    path: "base",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/bonus-ui/base/base.module").then((m) => m.BaseModule),
  },
  {
    path: "advance",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/bonus-ui/advance/advance.module").then((m) => m.AdvanceModule),
  },
  {
    path: "project",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/project/project.module").then((m) => m.ProjectModule),
  },

  {
    path: "ecommerce",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/e-commerce/e-commerce.module").then((m) => m.ECommerceModule),
  },
  {
    path: "email",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/email/email.module").then((m) => m.EmailModule),
  },
  {
    path: "chat",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/chat/chat.module").then((m) => m.ChatModule),
  },
  {
    path: "user",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/users/users.module").then((m) => m.UsersModule),
  },
  {
    path: "roles",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/roles/roles/roles.module").then((m) => m.RolesModule),
  },
  {
    path: "subroles",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/roles/sub-roles/sub-roles.module").then((m) => m.SubRolesModule),
  },
  {
    path: "bookmarks",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/bookmarks/bookmarks.module").then((m) => m.BookmarksModule),
  },
  {
    path: "file-manager",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/file-manager/file-manager.module").then((m) => m.FileManagerModule),
  },
  {
    path: "tasks",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/tasks/tasks.module").then((m) => m.TasksModule),
  },
  {
    path: "calender",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/calender/calender.module").then((m) => m.CalenderModule),
  },
  {
    path: "social-app",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/social-app/social-app.module").then((m) => m.SocialAppModule),
  },
  {
    path: "todo",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/todo/todo.module").then((m) => m.TodoModule),
  },
  {
    path: "buttons",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/buttons/buttons.module").then((m) => m.ButtonsModule),
  },
  {
    path: "editor",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/editors/editor.module").then((m) => m.EditorModule),
  },
  {
    path: "chart",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/charts/charts.module").then((m) => m.ChartModule),
  },
  {
    path: "icons",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/icons/icons.module").then((m) => m.IconsModule),
  },
  {
    path: "form",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/forms/forms.module").then((m) => m.FormModule),
  },
  {
    path: "table",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/table/table.module").then((m) => m.TableModule),
  },
  {
    path: "cards",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/cards/cards.module").then((m) => m.CardsModule),
  },
  {
    path: "sample-page",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/others/sample/sample.module").then((m) => m.SampleModule),
  },
  {
    path: "gallery",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/gallery/gallery.module").then((m) => m.GalleryDemoModule),
  },
  {
    path: "blog",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/blog/blog.module").then((m) => m.BlogModule),
  },
  {
    path: "faq",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/faq/faq.module").then((m) => m.FaqModule),
  },
  {
    path: "job",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/job-search/job-search.module").then((m) => m.JobSearchModule),
  },
  {
    path: "learning",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/learning/learning.module").then((m) => m.LearningModule),
  },
  {
    path: "knowledgebase",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/knowledge-base/knowledge-base.module").then((m) => m.KnowledgeBaseModule),
  },
  {
    path: "map",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/map/map.module").then((m) => m.MapModule),
  },
  {
    path: "support-ticket",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps/support-ticket/support-ticket.module").then((m) => m.SupportTicketModule),
  },
  {
    path: "search-pages",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/others/search-result/search-result.module").then((m) => m.SearchResultModule),
  },
  {
    path: "test-api",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/test-api/test-api.module").then((m) => m.TestApiModule),
  },
  {
    path: "partners",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/partner/partner.module").then(m => m.PartnerModule),
  },
  {
    path: "partner-groups",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/partner-groups/partner-groups.module").then(m => m.PartnerGroupsModule),
  },
  {
    path: "orders",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/order/order.module").then(m => m.OrderModule),
  },
  {
    path: "projects",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/projects/projects.module").then((m) => m.ProjectsModule),
  },
  {
    path: "project-cost",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/project-cost/project-cost.module").then((m) => m.ProjectCostModule),
  },
  {
    path: "contacts",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/contacts/contacts.module").then(m => m.ContactsModule),
  },
  {
    path: "impressum",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/impressum/impressum.module").then(m => m.ImpressumModule),
  },
  {
    path: "support",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/support/support.module").then(m => m.SupportModule),
  },
  {
    path: "backup",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/backup/backup.module").then(m => m.BackupModule),
  },
  {
    path: "audit",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/audit/audit.module").then(m => m.AuditModule),
  },
  {
    path: "health",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/health/health.module").then(m => m.HealthModule),
  },
  {
    path: "shiftempo-operators",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/operators/operators.module").then(m => m.OperatorsModule),
  },
  {
    path: "shiftempo-plans",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/plans/plans.module").then(m => m.PlansModule),
  },
  {
    path: "anylogic",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/anylogic/anylogic.module").then(m => m.AnyLogicModule),
  },
  {
    path: "users",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/user/user.module").then(m => m.UserModule),
  },
  {
    path: "product",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/products/products.module").then((m) => m.ProductsModule),
  },
  {
    path: "technologists",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/technologist/technologist.module").then(m => m.TechnologistModule),
  },
  {
    path: "barcoded-products",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/barcode/barcoded-product/barcoded-product.module").then(m => m.BarcodedProductModule),
  },
  {
    path: "storage-areas",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/barcode/storage-area/storage-area.module").then(m => m.StorageAreaModule),
  },
  {
    path: "stock-informations",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/barcode/stock-information/stock-information.module").then(m => m.StockInformationModule),
  },
  {
    path: "warehouse-transactions",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/barcode/warehouse-transaction/warehouse-transaction.module").then(m => m.WarehouseTransactionModule),
  },
  {
    path: "barcode-dashboard",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/barcode/dashboard/dashboard.module").then(m => m.DashboardModule),
  },

  {
    path: "machines",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/machines/machines.module").then(m => m.MachinesModule),
  },
  {
    path: "machine-locations",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/machine-locations/machine-locations.module").then(m => m.MachineLocationsModule)
  },
  {
    path: "andons",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/andon/andon.module").then(m => m.AndonModule)
  },
  {
    path: "msp-product",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-msp/msp-products/msp-products.module").then((m) => m.MSPProductsModule),
  },
  {
    path: "materials",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-msp/materials/material.module").then(m => m.MaterialsModule)
  },
  {
    path: "product-matching",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-msp/productMatching/productMatching.module").then(m => m.ProductMatchingModule)
  },
  {
    path: "msp-dashboard",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-msp/dashboard/msp-dashboard.module").then(m => m.MSPDashboardModule)
  },
  {
    path: "houses",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/mushroom-houses/mushroom-houses.module").then(m => m.MushroomHousesModule)
  },
  {
    path: "harvests",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/harvest/harvest.module").then(m => m.HarvestModule)
  },
  {
    path: "cycles",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/production-cycles/production-cycles.module").then(m => m.ProductionCyclesModule)
  },
  {
    path: "peats",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/peat/peat.module").then(m => m.PeatModule)
  },
  {
    path: "peatLabs",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/peat-lab/peat-lab.module").then(m => m.PeatLabModule)
  },
  {
    path: "irrigations",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/irrigation/irrigation.module").then(m => m.IrrigationModule)
  },
  {
    path: "productionByTrucks",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/production-by-truck/production-by-truck.module").then(m => m.ProductionByTruckModule)
  },
  {
    path: "spawns",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/spawn/spawn.module").then(m => m.SpawnModule)
  },
  {
    path: "soils",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/soil/soil.module").then(m => m.SoilModule)
  },
  {
    path: "klavCodes",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/klav-code/klav-code.module").then(m => m.KlavCodeModule)
  },
  {
    path: "spawnLabs",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/spawn-lab/spawn-lab.module").then(m => m.SpawnLabModule)
  },
  {
    path: "spawnFactories",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/spawn-factory/spawn-factory.module").then(m => m.SpawnFactoryModule)
  },
  {
    path: "compostLabs",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/compost-lab/compost-lab.module").then(m => m.CompostLabModule)
  },
  {
    path: "compostPhase1s",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/compost-phase1/compost-phase1.module").then(m => m.CompostPhase1Module)
  },
  {
    path: "compostPhase2s",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/compost-phase2/compost-phase2.module").then(m => m.CompostPhase2Module)
  },
  {
    path: "compostPhase3s",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/compost-phase3/compost-phase3.module").then(m => m.CompostPhase3Module)
  },
  {
    path: "composts",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/compost/compost.module").then(m => m.CompostModule)
  },
  {
    path: "types",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/type/type.module").then(m => m.TypeModule)
  },
  {
    path: "enrichmentMaterials",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/enrichment-material/enrichment-material.module").then(m => m.EnrichmentMaterialModule)
  },
  {
    path: "korona-trucks",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/truck/truck.module").then(m => m.KoronaTruckModule)
  },
  {
    path: "fs/resource-tools",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-fs/contacts/resource-tool.module").then(m => m.ResourceToolsModule)
  },
  {
    path: "andon",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/andon/andon.module").then(m => m.AndonModule)
  },
  {
    path: "machines",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/machines/machines.module").then(m => m.MachinesModule)
  },
  {
    path: "maintenance",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/maintenance/maintenance.module").then(m => m.MaintenanceModule)
  },
  {
    path: "technologists",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/technologist/technologist.module").then(m => m.TechnologistModule),
  },
  {
    path: "resources",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/resources/resources.module").then(m => m.ResourcesModule),
  },
  {
    path: "sales",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/sales/sales.module").then(m => m.SalesModule),
  },
  {
    path: "fittingker-machine-tasks",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-fittingker/machine-task/machine-task.module").then(m => m.MachineTaskModule),
  },
  {
    path: "fittingker-products",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-fittingker/product/product.module").then(m => m.ProductModule),
  },
  {
    path: "fittingker-orders",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-fittingker/order/order.module").then(m => m.OrderModule),
  },
  {
    path: "fittingker-mastertasks",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-fittingker/master-task/master-task.module").then(m => m.MasterTaskModule),
  },
  {
    path: "fittingker-machines",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-fittingker/machines/machines.module").then(m => m.MachinesModule),
  },
  {
    path: "fittingker-material-requirements",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-fittingker/material-requirements/material-requirements.module").then(m => m.MaterialRequirementsModule),
  },
  {
    path: "resource-datas",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/resource-data/resource-data/resource-data.module").then(m => m.ResourceDataModule),
  },
  {
    path: "demands",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-nadudvari/demands/demands.module").then(m => m.DemandsModule),
  },
  {
    path: "ingredients",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-nadudvari/ingredients/ingredients.module").then(m => m.IngredientsModule),
  },
  {
    path: "plans",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-nadudvari/plans/plans.module").then(m => m.PlansModule),
  },
  {
    path: "daily-production",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-nadudvari/daily-production/daily-production.module").then(m => m.DailyProductionModule),
  },
  {
    path: "daily-scheduler",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-nadudvari/daily-scheduler/nadudvari-daily-scheduler.module").then(m => m.NadudvariDailySchedulerModule),
  },
  {
    path: "settings",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/settings/settings.module").then(m => m.SettingsModule),
  },
  {
    path: "home",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/home-redirect/home-redirect.module").then(m => m.HomeRedirectModule),
  },
  {
    path: "home/edit",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/home/home.module").then(m => m.HomeModule),
  },
  {
    path: "unauthorized",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/unauthorized/unauthorized.module").then(m => m.UnauthorizedModule),
  },
  {
    path: "confirm-email",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/confirm-email/confirm-email.module").then(m => m.ConfirmEmailModule),
  },
  {
    path: "nadudvari-machine-data",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-nadudvari/machine-data/nadudvari-machine-data.module").then(m => m.NadudvariMachineDataModule),
  },
  {
    path: "nadudvari-resources",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-nadudvari/resources/nadudvari-resources.module").then(m => m.NadudvariResourcesModule),
  },
  {
    path: "nadudvari-jams",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-nadudvari/jams/jams.module").then(m => m.JamsModule),
  },
  {
    path: "cleaning-attributes",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-nadudvari/cleaning/cl-attributes/cl-attributes.module").then(m => m.CleaningAttributesModule),
  },
  {
    path: "cleaning-rules",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-nadudvari/cleaning/cl-rules/cl-rules.module").then(m => m.CleaningRulesModule),
  },
  {
    path: "holiday",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/calendar/calendar.module").then(m => m.CalendartModule),
  },
  {
    path: "capacities",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-fittingker/capacities/capacities.module").then(m => m.CapacitiesModule),
  },
  {
    path: "fittingker-dashboard",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-fittingker/dashboard/fittingker-dashboard.module").then(m => m.FittingkerDashboardModule),
  },
  {
    path: "dashboard",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/dashboard/epic-dashboard.module").then(m => m.EPICDashboardModule),
  },
  {
    path: "nadudvari-product",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-nadudvari/product/product.module").then(m => m.NadudvariProductModule),
  },
  {
    path: "nadudvari-message",
    loadChildren: () => import("../../components/apps-nadudvari/message/message.module").then(m => m.NadudvariMessageModule),
  },
  {
    path: "fittingker-statistics",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-fittingker/statistics/fittingker-dashboard.module").then(m => m.FittingkerStatisticsModule),
  },
  {
    path: "nadudvari-statistics",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-nadudvari/statistics/nadudvari-statistics.module").then(m => m.NadudvariStatisticsModule),
  },
  {
    path: "nadudvari-dashboard",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-nadudvari/dashboard/nadudvari-dashboard.module").then(m => m.NadudvariDashboardModule),
  },
  {
    path: "shift-planner",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/shift-planner/shift-planner.module").then(m => m.ShiftPlannerModule),
  },
  {
    path: "korona-dashboard",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/dashboard/korona-dashboard.module").then(m => m.KoronaDashboardModule),
  },
  {
    path: "korona-statistics",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/statistics/korona-statistics.module").then(m => m.KoronaStatisticsModule),
  },
  {
    path: "diseases",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-korona/disease/disease.module").then(m => m.DiseaseModule),
  },
  {
    path: "fittingker-import",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-fittingker/import/import.module").then(m => m.ImportModule),
  },
  {
    path: "walter-transaction",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-fittingker/walter-transactions/walter-transaction.module").then(m => m.WalterTransactionModule),
  },
  {
    path: "fittingker-production-orders",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-fittingker/production-order/production-order.module").then(m => m.ProductionOrderModule),
  },
  {
    path: "fittingker-machines/capacity-timeline",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-fittingker/capacity-timeline/capacity-timeline.module").then(m => m.CapacityTimelineModule),
  },
  {
    path: "shiftempo-work-in-progresses",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/work-in-progresses/work-in-progresses.module").then(m => m.WorkInProgressesModule),
  },
  {
    path: "shiftempo-products",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/shiftempo-products/shiftempo-products.module").then(m => m.ShiftempoProductsModule),
  },
  {
    path: "scrap",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-epic/scrap/scrap.module").then(m => m.ScrapModule),
  },
  {
    path: "measurements",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-lab/measurements/measurements.module").then(m => m.MeasurementsModule),
  },
  {
    path: "scheduling",
    canActivate: [AdminGuard],
    loadChildren: () => import("../../components/apps-nadudvari/scheduling/scheduling.module").then(m => m.SchedulingModule),
  },
  {
    path: "**",
    redirectTo: "home",
  },
];